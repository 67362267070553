<template>
  <section>
    <b-table :items="items" :fields="fields"
      :borderless='false' :hover="true"
      :sticky-header="true"
    />
  </section>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  data() {
    return{
      items: [],
      fields: [
        { key: 's', label: this.$t('Merchant code') },
        { key: 'startDatetime', label: this.$t('Merchant name') },
        { key: 'endDateTime', label: this.$t('Active') },
      ],
    }
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>