<template>
  <section>
    <!-- WMS Integration -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('WMS Integration') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-button
          class="ll-addbtn"
          variant="info"
          @click="openWMS"
        >
          <span>Add</span>
        </b-button>
        <b-table
          class="position-relative ll-table"
          :items="rows"
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :striped="true"
          :hover="true"
          :sticky-header="true"
        >
          <template #cell(warehouseName)="data">
            <b-form-input
              v-model="data.item.warehouseName"
              class="ll-inputWidth"
            />
          </template>
          <template #cell(status)>
            <b-badge
              variant="light-info"
              class="ll-badge"
            >
              <span>Connected</span>
            </b-badge>
            <!-- <b-badge
              :variant="data.item.isConnect ? 'light-info' : 'light-danger'"
              class="ll-badge"
              v-else
            >
              <span>{{data.item.isConnect? 'Connected' : 'Disconnect'}}</span>
            </b-badge> -->
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button
                variant="flat-info"
                class="btn-icon rounded-circle ll-act mr-1"
              >
                <feather-icon icon="LinkIcon" variant="info"
                style="{'color': '#409eff'}"/>
              </b-button>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle ll-act"
                @click="onDelwms(data)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div>
            <!-- <div class="d-flex" v-else>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle ll-act mr-1"
                @click="onConnect(data.item)"
              >
                <feather-icon icon="LinkIcon"
                :style="{'color': data.item.isConnect ? '#409eff' : 'red'}"/>
              </b-button>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle ll-act"
                @click="onDelwms(data.index)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div> -->
          </template>
        </b-table>
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap ll-p" v-if="false">
          <div class="d-flex align-items-center mb-0 mt-1 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="add-wms"
      centered
      title=""
      size="lg"
      v-model="iswms"
    >
      <b-form-group
        :label="$t('Select the warehouse to proceed')"
        label-for="s"
        class="ll-wmsbox"
      >
        <v-select
          :options="wmsList"
          label="warehouseCode"
          @input="selwms"
          v-model="wms"
          placeholder="select the warehouse"
        ></v-select>
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="nextWms"
        >
          {{ $t('Next') }}
        </b-button>
      </template>
    </b-modal>
    <!-- TWMS -->
    <b-modal
      id="twms"
      ref="twms"
      centered
      title=""
      size="lg"
      v-model="istwms1"
    >
      <b-form-group
        :label="tokenText"
        label-for="s"
      >
        <b-form-input
          v-model="token"
        />
      </b-form-group>
      <b-form-group
        label="Input Client Code (Storer Code)"
        label-for="s"
        v-if="iskwmsCode"
      >
        <b-form-input
          v-model="clientCode"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="onTwms"
        >
          {{ $t('Submit') }}
        </b-button>
      </template>
    </b-modal>
    <!-- TWMS Status-->
    <b-modal
      id="twmsok"
      centered
      title=""
      size="lg"
      v-model="istwms2"
      hide-footer
    >
      <div v-if="istwmsok" class="ll-twmsbox">
        <p>
          <svg aria-hidden="true" class="ll-successIcon">
            <use xlink:href="#icon-form_icon_normal"></use>
          </svg>
        <p>
        <p class="font-weight-bolder">Connected!</p>
        <p class="">Please check the information</p>
        <b-button
          variant="primary"
          class="btn"
          @click="twmsClose"
        >
          {{ $t('Close') }}
        </b-button>
      </div>
      <div v-if="!istwmsok" class="ll-twmsbox">
        <p>
          <svg aria-hidden="true" class="ll-failIcon">
            <use xlink:href="#icon-cancel1"></use>
          </svg>
        </p>
        <p class="font-weight-bolder">Connect failed</p>
        <p class="">Please check the token is input correctly</p>
        <b-button
          variant="primary"
          class="btn"
          @click="twmsRetry"
        >
          {{ $t('Retry') }}
        </b-button>
      </div>
    </b-modal>
    <!-- KWMS -->
    <b-modal
      id="kwms"
      centered
      title="Please input below information to proceed."
      size="lg"
      v-model="iskwms"
    >
    <validation-observer ref="rules">
      <b-row>
        <b-col md="12">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Company Name')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Company Name') }}</label>
              <b-form-input v-model="shipper.company"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Name')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Name') }}</label>
              <b-form-input v-model="shipper.shipperName"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Company')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Company') }}</label>
              <b-form-input v-model="shipper.shipperCompany"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Phone')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Phone') }}</label>
              <b-form-input v-model="shipper.shipperPhone" type="number"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Address')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Address') }}</label>
              <b-form-input v-model="shipper.shipperAddress"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Country')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Country') }}</label>
              <b-form-input v-model="shipper.shipperCountry"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Province')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Province') }}</label>
              <b-form-input v-model="shipper.shipperProvince"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('City')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('City') }}</label>
              <b-form-input v-model="shipper.shipperCity"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Email')"
              rules="required|email"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Email') }}</label>
              <b-form-input v-model="shipper.shipperEmail" type="email"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Shipper')+' '+ $t('Post Code')"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Post Code') }}</label>
              <b-form-input v-model="shipper.shipperPostCode" type="number"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="s"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Shipper')+' '+ $t('Country Code') }}</label>
              <b-form-input v-model="shipper.shipperCountryCode" type="number"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click.prevent="validationForm"
        >
          {{ $t('Save') }}
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormGroup, BFormInput, BButton,
  BFormCheckbox, BFormRadio, BBadge, BPagination, BTable, BFormSelect,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BTable,
    vSelect,
    flatPickr,
    BButton,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BBadge,
    BPagination,
    BTable,
    BFormSelect,
    BModal,
  },
  directives: {},
  props: {
    fullfillmentInfoList: Array,
    byId: String,
  },
  data() {
    return{
      required,
      email,
      iswms: false,
      wmsList: [],
      wms: null,
      twms: null,
      istwms1: false,
      istwms2: false,
      istwmsok: true,
      iskwms: false,
      shipper: {
        company: null,
        shipperName: null,
        shipperCompany: null,
        shipperAddress: null,
        shipperCity: null,
        shipperProvince: null,
        shipperCountryCode: null,
        shipperCountry: null,
        shipperPostCode: null,
        shipperPhone: null,
        shipperEmail: null,
      },
      columns: [
        { key: 'warehouseCode', label: this.$t('Warehouse')+' ' + this.$t('Code') },
        { key: 'warehouseName', label: this.$t('WMS Integration Name') },
        { key: 'shipper.company', label: this.$t('Company Name') },
        { key: 'token', label: this.$t('Code') },
        { key: 'shipper.shipperName', label: this.$t('Shipper')+' '+ this.$t('Name') },
        { key: 'shipper.shipperCompany', label: this.$t('Shipper')+' '+ this.$t('Company') },
        { key: 'shipper.shipperPhone', label: this.$t('Shipper')+' '+ this.$t('Phone') },
        { key: 'shipper.shipperAddress', label: this.$t('Shipper')+' '+ this.$t('Address') },
        { key: 'shipper.shipperCountry', label: this.$t('Shipper')+' '+ this.$t('Country') },
        { key: 'shipper.shipperProvince', label: this.$t('Shipper')+' '+ this.$t('Province') },
        { key: 'shipper.shipperCity', label: this.$t('Shipper')+' '+ this.$t('City') },
        { key: 'shipper.shipperEmail', label: this.$t('Shipper')+' '+ this.$t('Email') },
        { key: 'shipper.shipperPostCode', label: this.$t('Shipper')+' '+ this.$t('Post Code') },
        { key: 'shipper.shipperCountryCode', label: this.$t('Shipper')+' '+ this.$t('Country Code') },
        { key: 'status', label: this.$t('Status') },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      pageOptions: [50, 100, 200],
      page: null,
      size: 15,
      totalSize: null,
      totalPage: null,
      token: null,
      isConnect: true,
      connectText: false,
      tokenText: 'Input TWMS API token',
      iskwmsCode: false,
      clientCode: null,
    }
  },
  watch: {
    // info(val) {
    //   if (val) {
    //     this.querywms()
    //   }
    // }
  },
  created() {},
  mounted() {
    this.querywms()
  },
  destroyed() {
  },
  methods: {
    openWMS() {
      this.wms = null
      this.iswms = true
    },
    querywms() {
      setTimeout(() => {
        this.rows = this.fullfillmentInfoList ? this.fullfillmentInfoList : []
        // if (this.rows) {
        //   this.rows.map(item=>{
        //     item.isConnect = true
        //   })
        // }
      }, 2000)
      this.$http.get('/admin/settings/organization/getWarehouseList').then(res => {
        this.wmsList = res.data.data
      })
    },
    selwms(d) {
      this.wms = d.warehouseCode
      this.token = null
      this.clientCode = null
    },
    nextWms(){
      if (this.wms) {
        this.iswms = false
        if (this.wms == 'SG-KERRY01'){
          // KWMS
          // this.iskwms = true
          this.istwms1 = true
          this.tokenText = 'Input Project Name (URL Parameter)'
          this.iskwmsCode = true
        } else {
          // TWMS
          this.istwms1 = true
          this.tokenText = 'Input TWMS API token'
          this.iskwmsCode = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select the warehouse',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    onTwms() {
      if (this.token) {
        this.istwms1 = false
        if (this.wms == 'SG-KERRY01'){
          // KWMS
          // this.$http.get(`/admin/settings/organization/connectTwms?warehouseCode=${this.wms}&token=${this.token}&clientCode=${this.clientCode}`).then(res => {
          //   if (res.data.code == '200') {
          //     this.istwmsok = true
          //     this.iskwms = true
          //   } else {
          //     this.istwms2 = true
          //     this.istwmsok = false
          //     this.token = null
          //     this.clientCode = null
          //   }
          // })
          this.iskwms = true
        } else {
          // TWMS
          this.$http.get(`/admin/settings/organization/connectTwms?warehouseCode=${this.wms}&token=${this.token}`).then(res => {
            if (res.data.code == '200') {
              this.istwmsok = true
              this.istwms2 = true
            } else {
              this.istwms2 = true
              this.istwmsok = false
              this.token = null
            }
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please enter token',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
    },
    twmsClose() {
      this.istwms2 = false
      if (this.connectText) {
        return
      } else {
        let arr = []
        for (let i=65,j=0;i<91;i++,j++){
          arr[j] = String.fromCharCode(i)
        }
        let obj = {}
        obj.warehouseCode = this.wms
        obj.token = this.token
        obj.shipper = null
        this.rows.push(obj)
        this.rows.map((item,index)=>{
          item.warehouseName = 'Warehouse'+ arr[index]
        })
        this.$emit('wmsInfo', this.rows)
      }
    },
    twmsRetry() {
      this.istwms2 = false
      if (this.connectText) {
        return
      } else {
        this.istwms1 = true
      }
    },
    onSavekwms(){
      this.iskwms = false
      let arr = []
      for (let i=65,j=0;i<91;i++,j++){
        arr[j] = String.fromCharCode(i)
      }
      let obj = {}
      obj.warehouseCode = this.wms
      obj.token = this.token
      obj.clientCode = this.clientCode
      obj.shipper = this.shipper
      this.rows.map((item,index)=>{
        item.warehouseName = 'Warehouse'+ arr[index]
      })
      this.$http.post('/admin/settings/organization/connectKwms',obj).then(res => {
        if (res.data.code == '200') {
          this.iskwms = false
          this.rows.push(obj)
          this.rows.map((item,index)=>{
            item.warehouseName = 'Warehouse'+ arr[index]
          })
          this.$emit('wmsInfo', this.rows)
        } else {
          this.istwms1 = true
          this.tokenText = 'Input Project Name (URL Parameter)'
          this.iskwmsCode = true
          this.iskwms = false
          this.token = null
          this.clientCode = null
        }
      })
    },
    handleChangePage(page) {
    },
    handlePageChange(active) {
    },
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.onSavekwms()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    onDelwms(d) {
      if (d.item.id) {
        this.$http.delete(`/admin/settings/organization/delFulfillment?id=${d.item.id}`).then(res=>{
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.rows.splice(d.index,1)
            this.$emit('wmsInfo', this.rows)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      } else {
        this.rows.splice(d.index,1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Delete Success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('wmsInfo', this.rows)
      }
    },
    onConnect(item) {
      item.isConnect = !item.isConnect
      this.rows.push()
      if (item.isConnect) {
        this.$http.get(`/admin/settings/organization/connectTwms?warehouseCode=${item.warehouseCode}&token=${item.token}`).then(res => {
          if (res.data.code == '200') {
            this.istwms2 = true
            this.istwmsok = true
            this.connectText = true
            item.isConnect = true
          } else {
            this.istwms2 = true
            this.istwmsok = false
            this.connectText = true
            item.isConnect = false
          }
        })
      }
    }
  },
}
</script>

<style scoped>
.ll-inputWidth{
  width: 150px;
}
.ll-successIcon{
  fill: #28c76f;
  width: 5em;
  height: 5em;
}
.ll-failIcon{
  fill: #ea5455;
  width: 5em;
  height: 5em;
}
.ll-wmsbox{
  height: 300px;
}
.ll-twmsbox{
  text-align: center;
}
.searchBox{
  display: flex;
  justify-content: space-between;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radio{
  display: inline-block;
  margin-right: 10px;
}
.ll-addbtn{
  font-size: 14px;
  margin-bottom: 10px;
}
.ll-btnText{
  color: #5e5873;
  font-size: 16px;
}
</style>
<style>
button:focus{
  outline: none;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>